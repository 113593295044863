.btn.unnati-btn {
    background-color: #167af6;
    color: #fff;
    display: inline-flex;
    align-items: center;
    gap: 10px;
}

.btn.unnati-outline {
    border: 1px solid #167af6;
    color: #167af6;
}