.title {
    text-align: center;
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
}

.subtitle {
    text-align: center;
    font-size: 1.2rem;
    margin-bottom: 2rem;
}

.questions {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.question {
    width: 48%;
    margin-bottom: 2rem;
    padding: 1rem;
    border: 1px solid #ddd;
    border-radius: 8px;
}

.question-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #007bff;
    color: white;
    font-size: 1.5rem;
    margin-bottom: 1rem;
}

.question-content {
    margin-left: 1rem;
    width: 100%;
}

.question-content h3 {
    margin-top: 0;
    font-size: 1.5rem;
    font-weight: bold;
}

.question-content p {
    line-height: 1.6;
}

.support {
    text-align: center;
    margin-top: 2rem;
}

.support a {
    color: #007bff;
    text-decoration: none;
}